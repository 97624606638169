<template>
    <div class="text-center">
        <v-row align="center" class="mb-2">
            <v-divider />
            <v-col cols="auto" class="divider-label mx-1">Or</v-col>
            <v-divider />
        </v-row>

        <!-- <template v-if="error">
            RESPONSE: {{ response }}
            <hr>
            ERROR: {{ error }}
            <hr>
        </template> -->

        <base-button class="mb-4" @click="signInWithGoogle">
            <svg viewBox="0 0 512 512" class="service-logo">
                <path fill="#EA4335" d="M118.2,208.3c19.9-60.3,76.5-103.6,143.7-103.6c36.1,0,68.6,12.8,94.3,33.7L430.5,64C385.2,24.4,327,0,261.8,0   C160.9,0,74,57.6,32.3,141.9L118.2,208.3z" />
                <path fill="#34A853" d="M348,384.3c-23.3,15-52.8,23-86.2,23c-66.9,0-123.3-43-143.4-102.9l-86.2,65.4C73.9,454.3,160.8,512,261.8,512   c62.6,0,122.4-22.2,167.1-64L348,384.3z" />
                <path fill="#4A90E2" d="M428.9,448c46.8-43.7,77.2-108.7,77.2-192c0-15.1-2.3-31.4-5.8-46.5H261.8v98.9h137.3   c-6.8,33.3-25,59-51.1,75.9L428.9,448z" />
                <path fill="#FBBC05" d="M118.4,304.4c-5.1-15.2-7.9-31.4-7.9-48.4c0-16.7,2.7-32.7,7.6-47.7l-85.9-66.4C15.1,176.2,5.8,214.9,5.8,256   c0,40.9,9.5,79.6,26.4,113.8L118.4,304.4z" />
            </svg>

            <span>
                {{ $t(`account.${signUp ? 'signUpWith' : 'signInWith'}`, { service: 'Google' }) }}
            </span>
        </base-button>

        <br>

        <base-button class="mb-4" @click="signInWithFacebook">
            <svg viewBox="0 0 512 512" class="service-logo">
                <path fill="#395185" d="M483.7,512c15.6,0,28.3-12.7,28.3-28.3V28.3C512,12.7,499.3,0,483.7,0H28.3C12.6,0,0,12.7,0,28.3v455.5   C0,499.3,12.6,512,28.3,512H483.7" />
                <path fill="#FFFFFF" d="M353.3,512V313.7h66.6l10-77.3h-76.5v-49.3c0-22.4,6.2-37.6,38.3-37.6l40.9,0V80.4c-7.1-0.9-31.4-3-59.6-3   c-59,0-99.4,36-99.4,102.1v57h-66.7v77.3h66.7V512H353.3" />
            </svg>

            <span>
                {{ $t(`account.${signUp ? 'signUpWith' : 'signInWith'}`, { service: 'Facebook' }) }}
            </span>
        </base-button>

        <br>

        <base-button v-if="notAndroid" class="mb-4" @click="signInWithApple">
            <svg viewBox="0 0 512 512" class="service-logo">
                <path d="M396.6,271.8c-0.9-65.2,53.1-95.9,54.9-97.7c-29.8-43.8-77.3-50.3-94-50.3c-40-3.7-78.2,23.3-97.7,23.3   c-20.5,0-51.2-23.3-84.7-22.3C131.3,125.7,91.3,149.9,69,189c-45.6,78.2-11.2,194.6,32.6,257.9c21.4,30.7,47.5,66.1,81,65.2   c32.6-0.9,44.7-21.4,83.8-21.4s50.3,21.4,84.7,20.5c34.4-0.9,56.8-31.6,78.2-63.3c25.1-36.3,34.4-70.7,35.4-72.6   C464.6,375.1,397.6,349.1,396.6,271.8L396.6,271.8z M332.4,81.9c17.7-21.4,29.8-52.1,27-81.9c-26.1,0.9-56.8,16.8-75.4,39.1   c-16.8,19.5-30.7,50.3-27,79.1C284.9,120.1,314.7,103.3,332.4,81.9L332.4,81.9z" />
            </svg>

            <span>
                {{ $t(`account.${signUp ? 'signUpWith' : 'signInWith'}`, { service: 'Apple' }) }}
            </span>
        </base-button>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { APPLE_OAUTH2_REDIRECT_URL } from '@/config';
import RouteNames from '@/router/names';
import { RawLocation } from 'vue-router';

export default Vue.extend({
    props: {
        signUp: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            response: null as any,
            error: null as any,
        };
    },

    computed: {
        notAndroid(): boolean {
            // Apple sign in is not currently supported on Android.
            // https://github.com/capacitor-community/apple-sign-in/issues/13
            return this.$store.state.platform !== 'android';
        },
    },

    mounted() {
        GoogleAuth.initialize();
    },

    methods: {
        async signInWithApple() {
            try {
                const authorization = await SignInWithApple.authorize({
                    clientId: 'org.iseechange.www', // This is a service ID, not the app ID!
                    redirectURI: APPLE_OAUTH2_REDIRECT_URL,
                    scopes: 'name email',
                    // state: '',
                    nonce: Math.random().toString(16).split('.')[1], // Maybe not used? Not sure.
                });

                this.response = authorization.response;

                const { identityToken, givenName, familyName } = authorization.response;

                if (identityToken) {
                    await this.$store.dispatch('accountSocialLogin', {
                        provider: 'apple',
                        idToken: identityToken,
                        accessToken: identityToken,
                        firstName: givenName,
                        lastName: familyName,
                    });

                    this.handleSignIn();
                } else {
                    throw new Error('Apple identity token not found');
                }
            } catch (error) {
                this.handleError(error);
            }
        },

        async signInWithFacebook() {
            try {
                const response = await FacebookLogin.login({ permissions: ['email', 'public_profile'] });
                this.response = response;

                if (response.accessToken?.token) {
                    const tokenResponse = await FacebookLogin.getCurrentAccessToken();

                    if (tokenResponse.accessToken) {
                        const { token } = tokenResponse.accessToken;
                        await this.$store.dispatch('accountSocialLogin', {
                            provider: 'facebook',
                            accessToken: token,
                        });

                        this.handleSignIn();
                    } else {
                        throw new Error('Facebook access token not found');
                    }
                }
            } catch (error) {
                this.handleError(error);
            }
        },

        async signInWithGoogle() {
            try {
                const response = await GoogleAuth.signIn();
                this.response = response;

                if (response.authentication) {
                    const { error } = await this.$store.dispatch('accountSocialLogin', {
                        provider: 'google',
                        idToken: response.authentication.idToken,
                        accessToken: response.authentication.accessToken,
                    });

                    if (error) {
                        throw error;
                    }

                    this.handleSignIn();
                } else {
                    throw new Error(`Google id token not found in ${JSON.stringify(response.authentication)}`);
                }
            } catch (error) {
                this.handleError(error);
            }
        },

        handleError(error: Error) {
            this.$store.dispatch('alertUser', {
                type: 'error',
                message: 'There was a problem using third party sign-in. This can happen as a side effect of ad blockers or strict privacy settings.'
            });

            this.error = error; // For dev only!

            console.error(error);
        },

        handleSignIn() {
            const user = this.$store.state.account.currentUser;

            if (user) {
                let nextRoute: RawLocation = String(this.$route.query.redirect ?? '/');

                if (nextRoute.includes('://')) {
                    location.assign(nextRoute);
                }

                if (!user.addressComponents.countryCode) {
                    nextRoute = {
                        name: RouteNames.REGISTER,
                        query: this.$route.query,
                    };
                }

                this.$router.push(nextRoute);
            } else {
                throw new Error('Not signed in!');
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
.divider-label {
    text-transform: uppercase;
}

.service-logo {
    height: 1.6em;
    margin-inline-end: 1em;
    width: 1.6em;
}
</style>
