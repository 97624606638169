<template>
    <validation-observer v-if="!currentUser || currentUserWasJustCreated" ref="observer" v-slot="{ invalid, handleSubmit }">
        <form data-test-id="Registration form" @submit.prevent="handleSubmit(register)">
            <div class="form-row">
                <validation-provider v-slot="{ errors }" :name="$t('account.firstName')" rules="required">
                    <base-input
                        v-model="form.firstname"
                        :label="$t('account.firstName')"
                        :placeholder="embedded ? '' : undefined"
                        :error-messages="errors"
                        data-test-id="First name field"
                        style="min-width: 15ch;"
                    />
                </validation-provider>

                <validation-provider v-slot="{ errors }" :name="$t('account.lastName')" rules="required">
                    <base-input
                        v-model="form.lastname"
                        :label="$t('account.lastName')"
                        :placeholder="embedded ? '' : undefined"
                        :error-messages="errors"
                        data-test-id="Last name field"
                        style="min-width: 15ch;"
                    />
                </validation-provider>
            </div>

            <div class="mb-4">
                <validation-provider v-slot="{ errors }" mode="eager" rules="phone_number">
                    <phone-input
                        v-model="form.mobilePhone"
                        :label="$t('account.phone')"
                        :placeholder="embedded ? '' : undefined"
                        type="tel"
                        :hint="$t('account.phoneOptional')"
                        persistent-hint
                        :error-messages="errors"
                        data-test-id="Phone field"
                    />
                </validation-provider>

                <transition-expand :value="Boolean(form.mobilePhone)">
                    <v-checkbox
                        v-model="form.smsPermitted"
                        :label="$t('account.phonePermitSms')"
                        :hint="$t('account.phonePermitSmsHint')"
                        persistent-hint
                    />
                </transition-expand>
            </div>

            <validation-provider v-slot="{ errors }" ref="emailRef" :name="$t('account.email')" rules="required|email">
                <base-input
                    v-model="form.email"
                    :label="$t('account.email')"
                    :placeholder="embedded ? '' : undefined"
                    type="email"
                    :loading="checkingEmail"
                    :hint="emailDomainsHint ? emailDomainsHint : undefined"
                    persistent-hint
                    :error-messages="form.email ? (emailInUseError ? [emailInUseError] : emailDomainsError ? [emailDomainsError] : errors) : []"
                    data-test-id="Email field"
                    @keyup="handleEmailChange"
                />
            </validation-provider>

            <validation-provider v-slot="{ errors }" :name="$t('account.password')" rules="required|min:8|one_number|one_capital">
                <base-input
                    v-model="form.password"
                    :label="$t('account.password')"
                    :placeholder="embedded ? '' : undefined"
                    type="password"
                    :error-messages="errors"
                    data-test-id="Password field"
                />
            </validation-provider>

            <div class="text-center my-4">
                <div>
                    <base-button
                        type="submit"
                        color="primary"
                        prepend-icon="arrow_forward"
                        :loading="registrationInProgress"
                        :disabled="invalid || checkingEmail || Boolean(emailInUseError) || Boolean(emailDomainsError)"
                        data-test-id="Submit button"
                    >
                        {{ $t('actions.next') }}
                    </base-button>
                </div>

                <div v-if="embedded" class="mt-4">
                    {{ $t('account.haveAccount') }}
                    <auth-button>{{ $t('account.signIn') }}</auth-button>
                </div>
            </div>
        </form>

        <third-party-log-in-buttons v-if="!hideThirdPartyOptions" sign-up class="mt-n3" />
    </validation-observer>

    <div v-else class="already-signed-in">
        <i18n tag="p" path="campaignLandingPages.alreadySignedIn.content.0">
            <template #name>{{ currentUser.firstName }}</template>
        </i18n>

        <i18n tag="p" path="campaignLandingPages.alreadySignedIn.content.1">
            <template #sightings>
                <router-link :to="{ name: 'posts' }">
                    {{ $t('campaignLandingPages.alreadySignedIn.sightingsLinkLabel') }}
                </router-link>
            </template>
        </i18n>

        <i18n v-if="$te(`campaignLandingPages.${$route.params.campaign}.learnMoreLink`)" tag="p" path="campaignLandingPages.alreadySignedIn.content.2">
            <template #learn>
                <a :href="$t(`campaignLandingPages.${$route.params.campaign}.learnMoreLink`)">
                    {{ $t('campaignLandingPages.alreadySignedIn.learnMoreLinkLabel') }}
                </a>
            </template>

            <template #partner>{{ $t(`campaignLandingPages.${$route.params.campaign}.partnerName`) }}</template>
        </i18n>

        <i18n tag="p" path="campaignLandingPages.alreadySignedIn.content.3">
            <template #name>{{ currentUser.firstName }}</template>

            <template #signout>
                <base-button faux-link @click="signOut">
                    {{ $t('campaignLandingPages.alreadySignedIn.signOutButtonLabel') }}
                </base-button>
            </template>
        </i18n>
    </div>
</template>

<script lang="ts">
import { CurrentUser, NewUser } from '@/types';
import Vue from '@/vueTyped';
import rules from '@/validation-rules';
import debounce from 'lodash/debounce';
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm';
import PhoneInput from '@/components/PhoneInput.vue';
import ThirdPartyLogInButtons from '@/pages/Account/ThirdPartyLogInButtons.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';
import AuthButton from './AuthButton.vue';

export default Vue.extend({
    components: {
        ValidationProvider,
        ValidationObserver,
        PhoneInput,
        ThirdPartyLogInButtons,
        TransitionExpand,
        AuthButton,
    },

    props: {
        embedded: Boolean,
        clientGroupInviteToken: { type: String, default: null },
        allowedEmailDomains: { type: [Array, () => null], default: null },
        hideThirdPartyOptions: Boolean,
    },

    data() {
        return {
            form: {
                firstname: '',
                lastname: '',
                email: this.$store.state.account.visitorEmail,
                mobilePhone: '',
                smsPermitted: true,
                password: '',
            },
            rules,
            checkingEmail: false,
            emailInUseError: '',
            registrationInProgress: false,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        emailDomainsHint(): string | null {
            if (!this.allowedEmailDomains || this.allowedEmailDomains.length === 0) return null;
            return `${this.$t('allowedDomains')} ${this.allowedEmailDomains.map(domain => `@${domain}`).join(', ')}`;
        },

        emailDomainsError(): string | null {
            const emailPresent = this.form.email.match(/.+@.+\..+/) !== null;
            if (!emailPresent) return null;

            if (!this.allowedEmailDomains || this.allowedEmailDomains.length === 0) return null;

            const domainOkay = this.allowedEmailDomains.some(domain => this.form.email.endsWith(`@${domain}`));
            if (domainOkay) return null;

            return this.emailDomainsHint;
        },

        currentUserWasJustCreated(): boolean {
            // Note, not reactive to time;
            // this will only reset when currentUser changes,
            // but that should be enough for this case.
            const veryRecently = new Date();
            veryRecently.setSeconds(veryRecently.getSeconds() - 5);
            return this.currentUser !== null && new Date(this.currentUser.createdAt) >= veryRecently;
        },
    },

    methods: {
        async handleEmailChange() {
            const emailValid = await (this.$refs.emailRef as any).validateSilent();

            if (emailValid.valid) {
                this.validateEmail(this.form.email);
            }
        },

        validateEmail: debounce(async function(this: any, email) {
            this.checkingEmail = true;
            this.emailInUseError = '';
            const result = await this.$store.dispatch('checkEmailValidity', email);
            this.checkingEmail = false;
            if (result.error) {
                this.emailInUseError = this.$t('account.addressInUse');
            }
        }, 500),

        async register() {
            this.registrationInProgress = true;

            const notifyMethod: NewUser['communicationPreferences']['notifyMethod'] = ['email', 'app'];
            if (this.form.smsPermitted) {
                notifyMethod.push('sms');
            }

            const newUser: NewUser = {
                firstName: this.form.firstname,
                lastName: this.form.lastname,
                email: this.form.email,
                mobilePhone: this.form.mobilePhone,
                password: this.form.password,
                addressComponents: {},
                communicationPreferences: { notifyMethod },
                signupSource: this.$store.state.platform,
            };

            const { error } = await this.$store.dispatch('accountRegister', {
                ...newUser,
                clientGroupInviteToken: this.clientGroupInviteToken
            });

            this.registrationInProgress = false;

            if (error) {
                this.$store.dispatch('alertUser', {
                    type: 'error',
                    message: this.$t('account.addressInUse'),
                });
            } else {
                this.$emit('success');
            }
        },

        signOut() {
            this.$store.dispatch('accountLogOut');
        },
    },
});
</script>

<style lang="postcss" scoped>
.form-row {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
}

.form-row > * {
    flex: 1 1 0;
}

.already-signed-in {
    font-size: 14px;
    line-height: 1.4;
    text-align: start;
}
</style>
